import { animated, useSpring, useSpringValue } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'

import styles from './Line.css'

export function Line({ withEnterAnimation = false, customParentStartTrigger = undefined, customStartPoint = undefined, layoutClassName = undefined }) {
  return <LineBase className={cx(styles.component, layoutClassName)} {...{ withEnterAnimation, customParentStartTrigger, customStartPoint }} />
}

export function LineWhite({ withEnterAnimation = false, customParentStartTrigger = undefined, customStartPoint = undefined, layoutClassName = undefined }) {
  return <LineBase className={cx(styles.componentWhite, layoutClassName)} {...{ withEnterAnimation, customParentStartTrigger, customStartPoint }} />
}

function LineBase({ withEnterAnimation, customParentStartTrigger, customStartPoint = 0, className }) {
  const scaleY = useSpringValue(0, { config: { mass: 0.5, tension: 600, friction: 50 } })
  const opacity = useSpringValue(0, { config: { mass: 0.3, tension: 750, friction: 75 } })
  const scale = useSpringValue(0, { config: { mass: 0.3, tension: 750, friction: 75 } })

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: customParentStartTrigger || triggers.custom(0.75) },
    end: { element: triggers.bottom(), scrollParent: triggers.custom(0.80) },
    onChange(progression) {
      const binaryTrigger = progression + customStartPoint >= 1 ? 1 : 0

      scaleY.start(easeIn(progression + customStartPoint))
      opacity.start(binaryTrigger)
      scale.start(binaryTrigger)
    }
  })

  return (
    <div className={cx(styles.componentBase, className)} {...{ ref }}>
      <span className={cx(styles.dot, withEnterAnimation && styles.withEnterAnimation)} />

      <div className={cx(styles.lineContainer, withEnterAnimation && styles.withEnterAnimation)}>
        <animated.span className={styles.line} style={{ scaleY }} />
      </div>

      <animated.span className={styles.destinationDot} style={{ opacity, scale }} />
    </div>
  )
}

export function LinePageHero({ lineHeight }) {
  const opacity = lineHeight ? 1 : 0

  const { scaleY } = useSpring({
    delay: 1000,
    scaleY: lineHeight ? 1 : 0,
    config: { mass: 1, tension: 50, friction: 20 },
  })

  return (
    <div className={styles.componentPageHero}>
      <span style={{ opacity }} className={styles.dot} />
      <animated.span style={{ scaleY, height: `${lineHeight}px` }} className={styles.line} />
    </div>
  )
}

function easeIn(x) {
  return x * x
}
