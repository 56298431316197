import {
  Image as ImageBase,
  ImageCropped as ImageCroppedBase,
  ImageCover as ImageCoverBase
} from '@kaliber/sanity-image'
import { useClientConfig } from '/machinery/ClientConfig'

export function Image({ image, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()

  return (
    <ImageBase
      sanityConfig={sanity.clients.readOnly}
      imgProps={{ ...imgProps, alt: image.alt }}
      {...{ image, layoutClassName }}
    />
  )
}

export function ImageCropped({ image, aspectRatio, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()

  return (
    <ImageCroppedBase
      sanityConfig={sanity.clients.readOnly}
      imgProps={{ ...imgProps, alt: image.alt }}
      {...{ image, aspectRatio, layoutClassName }}
    />
  )
}

export function ImageCover({ image, aspectRatio, imgProps = {}, layoutClassName = undefined }) {
  const { sanity } = useClientConfig()

  return (
    <ImageCoverBase
      sanityConfig={sanity.clients.readOnly}
      imgProps={{ ...imgProps, alt: image?.alt }}
      {...{ image, aspectRatio, layoutClassName }}
    />
  )
}
