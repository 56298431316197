import styles from './Heading.css'

export function HeadingXs({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXs, layoutClassName)} {...{ children, h }} />
}

export function HeadingSm({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentSm, layoutClassName)} {...{ children, h }} />
}

export function HeadingMd({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentMd, layoutClassName)} {...{ children, h }} />
}

export function HeadingLg({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentLg, layoutClassName)} {...{ children, h }} />
}

export function HeadingXl({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXl, layoutClassName)} {...{ children, h }} />
}

export function HeadingXxl({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXxl, layoutClassName)} {...{ children, h }} />
}

export function HeadingXxxl({ children, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXxxl, layoutClassName)} {...{ children, h }} />
}

function HeadingBase({ children, h, className }) {
  /** @type {any} */
  const HBase = `h${h}`

  return (
    <HBase className={cx(styles.componentBase, className)}>{children}</HBase>
  )
}
