import { useMediaQuery } from '@kaliber/use-media-query'

import { useCheckStringLength } from '/machinery/useCheckStringLength'

import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { LineWhite } from '/features/pageOnly/Line'

import mediaStyles from '/cssGlobal/media.css'
import styles from './HeroPageAlt.css'

export function HeroPageAlt({ title, introduction, abbreviation = undefined, introductionTitle, heroImage, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const hasLongTitle = useCheckStringLength({ strings: [ title ], allowedLength: 20 })


  return (
    <div data-x='hero' className={cx(styles.component, layoutClassName)}>
      {!isViewportLg && (
        <div className={styles.titleContainer}>
          <h1 className={cx(styles.heading, hasLongTitle && styles.longHeading)}>{title}</h1>
          {abbreviation && <p className={styles.titleAbbreviation}>{abbreviation}</p>}
        </div>
      )}

      <div className={styles.container}>
        <TitleAndImage image={heroImage} layoutClassName={styles.titleAndImageLayout} {...{ title, abbreviation, hasLongTitle }} />
        <LineAndIntro layoutClassName={styles.lineAndIntroLayout} {...{ introduction, introductionTitle }} />
      </div>

      {!isViewportLg && (
        <div className={styles.lineContainer}>
          <LineWhite layoutClassName={styles.lineLayout} />
        </div>
      )}
    </div>
  )
}

function TitleAndImage({ title, abbreviation, image, hasLongTitle, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  return (
    <div className={cx(styles.componentTitleAndImage, layoutClassName)}>
      {isViewportLg && (
        <div className={styles.titleContainer}>
          <h1 className={cx(styles.heading, hasLongTitle && styles.longHeading)}>{title}</h1>
          {abbreviation && <p className={styles.titleAbbreviation}>{abbreviation}</p>}
        </div>
      )}
      {image && (
        <div className={styles.imageContainer}>
          <ImageCover aspectRatio={isViewportLg ? 12 / 7 : 7 / 8} {...{ image }} />
        </div>
      )}
    </div>
  )
}

function LineAndIntro({ introductionTitle, introduction, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  return (
    <div className={cx(styles.componentLineAndIntro, layoutClassName)}>
      <div className={styles.lineAndIntroContainer}>
        {isViewportLg && (
          <div className={styles.lineContainer}>
            <LineWhite layoutClassName={styles.lineLayout} />
          </div>
        )}
        <div className={styles.introContainer}>
          {introductionTitle && <HeadingSm h='2'>{introductionTitle}</HeadingSm>}
          {introduction && <p className={styles.introduction}>{introduction}</p>}
        </div>
      </div>
    </div>
  )
}
